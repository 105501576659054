import { combineReducers, createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import logger from "redux-logger"

import { transcendReducer } from "./transcend"

const initStore = () => {
  // ---Redux DevTools
  let composeEnhancers = compose
  if (typeof window !== "undefined") {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  }

  const middlewares = [thunk]

  if (process.env.NODE_ENV === `development`) {
    middlewares.push(logger)
  }

  const store = createStore(
    combineReducers({
      transcend: transcendReducer,
    }),
    composeEnhancers(applyMiddleware(...middlewares))
  )

  return store
}

export default initStore
